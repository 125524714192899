.voucher-stats-container {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
  height: 380px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.voucher-table-container {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
  /* height: 280px; */
  margin-top: 40px;
  width: 100%;
  padding: 20px;
}

.voucher-stats-graph {
  width: 100%;
}

.voucher-stats,
.voucher-payments {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
}

.voucher-stats-row:first-child,
.voucher-payments-row:first-child {
  margin-top: 0px;
}

.voucher-stats-row,
.voucher-payments-row {
  margin: 10px;
  display: flex !important;
  height: 50px;
  /* width: 50%; */
}

.voucher-stats-graph,
.voucher-payments-graph {
  /* margin: 10px; */
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 280px;
}

.voucher-stats-graph-container,
.voucher-payments-graph-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* margin-right: 20px; */
  margin-top: 20px;
  align-items: center;
}

.voucher-stats-row span.stat,
.voucher-payments-row span.payment {
  width: 25%;
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 14px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  font-size: large;
  justify-content: center;
  align-items: center;
}

.voucher-payments .voucher-payments-row {
  height: 70px !important;
}

.voucher-stats-row span.stat.total {
  background-color: #337ab7;
}

.voucher-stats-row span.stat.approved {
  background-color: #38713c;
}

.voucher-stats-row span.stat.pending {
  background-color: #adadad;
}

.voucher-stats-row span.stat.declined {
  background-color: #ee5122;
}

.voucher-stats-row span.stat.redeemed {
  background-color: #f7a21a;
}

/* .voucher-payments-row span.payment.due {
  background-color: #50d6ff;
  align-items: center;
} */

.voucher-payments-row span.payment.cleared {
  background-color: #38713c;
  align-items: center;
}

.voucher-payments-row span.payment.due {
  background-color: #ed5548;
  align-items: center;
}

.voucher-stats-row span.description,
.voucher-payments-row span.payment-description {
  flex: 1;
  flex-direction: column;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 14px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  align-items: center;
  display: flex;
}

.voucher-stats-row span.description.total {
  background-color: #b4d4f1;
  color: #337ab7;
}

.voucher-stats-row span.description.approved {
  background-color: #e5f0eb;
  color: #38713c;
}

.voucher-stats-row span.description.pending {
  background-color: #f0f0f1;
  color: #6e6e73;
}
.voucher-stats-row span.description.declined {
  background-color: #fceae9;
  color: #ee5122;
}
.voucher-stats-row span.description.redeemed {
  background-color: #fef5e8;
  color: #f7a21a;
}

/* .voucher-payments-row span.payment-description.due {
  background-color: #edfaff;
  color: #edfaff;
} */
.voucher-payments-row span.payment-description.cleared {
  background-color: #e5f0eb;
  color: #38713c;
}
.voucher-payments-row span.payment-description.due {
  background-color: #fcebec;
  color: #ee5122;
}

.voucher-payments-row span p {
  /* color: #76777c; */
  text-transform: uppercase;
}

.voucher-details-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 265px;
}

.voucher-details-info-right {
  display: flex;
  border-radius: 15px;
  flex: 1;
  background: #ffffff;
  padding: 15px;
}

.voucher-details-info-left {
  padding: 15px;
  display: flex;
  border-radius: 15px;
  width: 65%;
  margin-right: 20px;
  background: #ffffff;
  justify-content: space-between;
}

.voucher-details-info-section {
  display: flex;
  flex-direction: column;
}

.voucher-details-info-section.right {
  width: 50%;
}

.voucher--details-info-title {
  text-transform: uppercase;
  color: #b4b4b5;
  margin-bottom: 5px;
}

.voucher--details-info-value {
  color: black;
  font-weight: 900;
  margin-bottom: 20px;
}

.voucher-details-info-status {
  margin-bottom: 0px;
  padding: 5px;
  border: 1px solid;
  border-radius: 15px;
  color: white;
  text-align: center;
}

.voucher-details-info-status.pending {
  background-color: #adadad;
}

.voucher-details-info-status.approved {
  background-color: #38713c;
}

.voucher-details-info-status.denied {
  background-color: #ee5122;
}

.voucher-details-info-status.redeemed {
  background-color: #ffe127;
}

.action-voucher-form {
  width: 50%;
  margin: auto;
}

.action-voucher-form .section {
  display: flex;
  align-items: center;
}

.action-voucher-form .section .title {
  width: 200px;
}

.action-voucher-form .section .input {
  width: 300px;
}

.voucher-edit {
  border: 1px solid;
  border-radius: 5px;
  width: 100px;
  background-color: #38713c;
  color: white;
  display: flex;
  justify-content: center;
  margin-right: 25px;
  padding: 5px;
}

.voucher-edit.deactivate {
  background-color: #ee5122;
}

.editCATForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.vouchers-ul {
  list-style: none;
}

ul.vouchers-ul.none li:before {
  content: "";
}

ul.vouchers-ul li:before {
  content: "✓";
}
