body{
    background-color: #F5F6FA;
    font-family: sans-serif;
    font-size: 14px;
    color:#000;
}
*{
    box-sizing: border-box;
}
a:focus {
    text-decoration: none;
}
.navbar-header{
    width:100%;
}
.navbar-header .btn{
    margin: 8px;
    float: right;
    background-color: #fff;
    color:#93959A;
}
.navbar-default{
    background-color: #fff;
    border: unset !important;
    padding: 0 15px;

}
.hiddenMenu{
    height: 50px !important;
    overflow: hidden !important;
}
.clickable{
    cursor: pointer;
}
.noPadding{
    padding: 0;
}
.profileNav{
    margin-right: unset;
}
.profileNav .btn{
    margin:8px;
    border-radius: 50%;
    height: 34px;
    text-align: center;
    width: 34px;
    padding: unset;
}
.container-fluid{
    padding: 0;

}
.navbar-brand{
    color: #46A142 !important;
    font-size: 24px;
    font-weight:700;
    padding:15px 10px !important
}
.headerNav{
    box-shadow: 0 0px 5px 0 rgba(32,33,36,0.1);
    
}
.sideNav{
    box-shadow: 0 0px 5px 0 rgba(32,33,36,0.1);
    background-color: #fff;
    overflow: auto;
    height: 100vh;
}
.Mappointer.Inprogress{
    background-color: rgba(74, 145, 226, 0.9);
    box-shadow:0px 0px 0px 5px rgba(74, 145, 226, 0.5)
}
.Mappointer.Pending{
    background-color: rgba(120, 17, 31, 0.9);
    box-shadow:0px 0px 0px 5px rgba(120, 17, 31, 0.5)
}
.Mappointer.Scheduled{
    background-color: rgba(245, 165, 35, 0.9);
    box-shadow:0px 0px 0px 5px rgba(245, 165, 35, 0.5);
}
.Mappointer.Completed{
    background-color: rgba(71, 161, 66, 0.9);
    box-shadow:0px 0px 0px 5px rgba(71, 161, 66, 0.5);
}
/*background-color: #46A142;
border:3px solid #46A142;*/
.Mappointer{
    width:15px;
    height: 15px;
    border-radius: 50%;
    line-height:15px;
    color:#fff;
    font-size:10px;
    text-align: center;
    vertical-align: middle;
}
.sideNav .list-group-item{
    font-size: 18px;
    border: unset;
    margin: 20px auto;
}
.sideNav .list-group-item a{
    color:#000;
}
.sideNav .list-group-item a svg{
    margin-right: 20px;
    color:#9B9B9B;
}
#sideNavBar.col-md-1
.sideNav .list-group-item a:hover{
    text-decoration: none;
    color:#46A142;
    font-weight:700
}
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover, .col-md-1 .sideNav .list-group-item.active a svg{
    background-color: #fff;
    color:#46A142;
    font-weight:700;
    font-size: 18px;
}
.list-group-item.active a, .list-group-item.active:focus a, .list-group-item.active:hover a{
    color:#46A142;
}
.title h4{
    color:#000;
    font-size: 18px;
}
.title h4 svg {
    color: #94979B;
    margin-right: 20px;

}
.card{
    background-color: #fff;
    box-shadow: 0 0px 1px 1px rgba(32,33,36,0.02);
    margin: 20px 20px 0px 0;
    
}
.content-container {
    background-color: #fff;
    box-shadow: 0 0px 1px 1px rgba(32,33,36,0.02);
    margin: 15px;
}
.card:hover, .card.active{
    box-shadow: 0 0px 5px 2px rgba(32,33,36,0.1);
   
}
.padding-normal{
    padding: 15px;
}
.margin-normal{
    margin: 15px;
}
.form-inline .form-group {
    margin: 0 15px 0 0 !important;
}
.card h5{
    color: #1B253A;
    font-size: 30px;

}
.form-inline label{
    display: block;
}
.form-inline .form-group{
    margin: 0 15px;
}
.form-inline .form-control{
   min-width: 200px;
   background-color: #fff;
}
.card p{
    font-size: 20px;
    
}
.card .inprogress p{
    color:#4A90E2;
}
.card .pending p{
    color:#78111E;
}
.card .scheduled p{
    color:#F5A623;
}
.card .completed p{
    color:#46A142;
}


.btn-primary {
    color: #fff !important;
    background-color: #46A142;
    border-color: #46A142;
}
.btn-primary:hover {
    color: #46A142 !important;
    background-color: #fff;
    border-color: #46A142;
}
.btn-primary:active, .btn-primary:focus{
    color: #46A142 !important;
    background-color: #fff;
    border-color: #46A142; 
}
.btn-secondary {
    color: #46A142;
    background-color: unset;

}
.btn-secondary:hover {
    color: #46A142;
    border:1px solid #46A142;
}
.form-signin{
    margin-top:20vh;
  }
  .form-signin input{
    margin-top:10px;
  }
  .form-signin button{
    margin-top:10px;
    margin-bottom:10px;
  }
  .sectionList{
    box-shadow: 0 0px 5px 0 rgba(32,33,36,0.1);
    background-color: #fff;
    height: 65vh;
    overflow-y: scroll;
  }
  .loading{
      background-image: url('../assets/loading.gif');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px;
      height: 20vh;
      position: relative;
      background-color: #fff;
      width: 100%;
  }
  .loading span{
   font-size: 14px;
    position:absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
}
  .sectionList .panel-body:hover{
      cursor: pointer;
      background-color: #f2f2f2;
  }
  .sectionDetails{
    background-color: #F2F2F2;
  }
  .sectionDetails .panel{
        background-color: unset; 
        border: unset;
         border-radius:unset;
        box-shadow:unset;
  }
  .sectionDetails .panel-title{
    border-bottom: 1px solid #DBDFE9;
    overflow: auto;
  }
  .sectionDetails .panel-title .btn{
    margin: 0px;
    float: right;
    background-color: unset;
    color: #93959A;
    border:unset;
  }
  .marginNormal{
      margin: 15px;
  }
  .marginTop{
    margin-top: 15px;
    overflow: auto;
}
.dropdown, .dropup{
    list-style: none;
}
.form-control{
    background-color: #F5F7FA;
    border:1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    min-height: 30px;
}
.leaflet-control-geosearch form input{
    background-color: #F5F7FA;
    border:1px solid #F5F7FA;
}
.leaflet-control-geosearch.bar form{
    background-color: #F5F7FA;
    border:1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.section{
    width: 100%;
    margin: 15px auto;
}
.section h5{
    font-size: 12px;
    color:#1B253A;
}
.section h5 span{
    font-size: 12px;
    color:#94979B;
}
.section p{
    color:#94979B
}

.sectionDetails hr{
background-color: #DBDFE9;
border-top: 1px solid #DBDFE9;
}

.panel-body:hover{
    color: #F2F2F2;
    
}
.modal-dialog{
    width:60%;
}
form .page-header{
    margin: 0px 0 20px;
}
.leaflet-left{
    position: relative;
    height: 100%;
    width: 100%;
}
.leaflet-left .leaflet-control{
    position: absolute;
    bottom: 30px;
    right:15px;
}
.tableCustom th{
    border:unset !important;
}
.tableCustom td {
    height: 50px !important;
    vertical-align: middle !important;
    line-height: 50px !important;
    border: unset !important;
  }
.btn-light{
    color:#78111E;
    background-color: unset;
}
.iconWrapper{
    width: 50px;
    height: 50px;
    position: absolute;
    left: -8px;
    top: 0;
    z-index: -65;
}
.iconWrapper img{
    position: relative;
    left: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    user-select: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    max-width: none;
}

/* Checkbox Update */
    .checkboxWrapper{
        width:18px;
        height:18px;
        position: relative;
        display: inline-block;
    }
    .checkboxWrapper .disabler{
        width:100%;
        height:100%;
        top:0;
        left:0;
        position: absolute;
        z-index: 50;
    }
    .checkboxWrapper .disabler input{
        position: relative;
        z-index: 40;
    }



/* Checkbox Update*/

/* Drawer Component */

.drawerLeft{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
}
.drawerLeft .overLay{
    height:100%;
}
.drawerLeft .drawerComponent{
    position: relative;
    height:100%;
    background-color: #fff;
}
.line-bottom{
    color:rgba(0, 0, 0, 0.8);
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
h5{
    font-size:  18px !important;
}
.drawerLeft .drawerComponent .item{
    min-height: 200px;
    border:1px solid rgba(0, 0, 0, 0.2);
    width:100%;
    padding: 15px;
    border-radius: 5px;
    position: relative;
}
.drawerLeft .drawerComponent .item span{
    display: block;
    margin: 5px 0;
    color:rgba(0, 0, 0, 0.8);
}
.drawerLeft .drawerComponent .item button{
   position: absolute;
   bottom: 15px;
   right:15px;
}
.drawerLeft .drawerComponent .item .map{
   background-color: rgba(0, 0, 0, 0.1);
   height: 100px;
   width:100%;
 }


 /*
 Summary Page view on service provider detials
  */

  .userIcon{
      height: 100px;
      width:100px;
      background-color: #F5F6FA;
      border-radius: 50%;
      line-height: 100px;
      vertical-align: middle;
      text-align: center;
  }
  .userIcon svg{
    font-size: 50px;
    margin-top: 22px;
    line-height: 100px;
    columns: #707070 !important;
  }
  .summary .value{
      font-weight: 600;
      color:#000;
      padding-left: 5px;
      display: inline-block;
  }
  .summary .key{
    color:#707070;
    display: block;
    padding-left: 5px;
    margin: 15px 0;
    height: 40px;
}
.tag-filter-section{

}
.tag-filter-section .btn{
    margin: 0 30px 0 0;
    color:#707070;
    font-weight: 600;
}
.react-bs-table .table-bordered>tbody>tr>td {
    cursor: pointer;
}
.table-onScreen{
    max-height: 400px;
}
.padding-top{
    padding-top: 15px;
}
.table-header{
    position: sticky;
    top:0;
    background-color: #fff;
}
.onMobile{
    display: none;
}

/*

Responsive

*/
@media only screen and (max-width: 986px) {
    .sideNav{
        height: 50px;
    overflow: hidden;
    }
    .sideNav.hiddenMenu{
        height: auto !important;
        overflow: auto !important;
    }
    .onMobile{
        display: block !important;
    }
}
@media only screen and (min-width: 986px) {
    .sideNav{
        height: 100vh !important;
    overflow: auto !important;
    }
    .onMobile{
        display: none;
    }
}